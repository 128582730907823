/* fichier JS de base commun quelques soient les versions du projet web */

// import 'bootstrap'
import 'bootstrap/js/dist/collapse'
import 'bootstrap/js/dist/dropdown'

// lazy loading => https://github.com/aFarkas/lazysizes
// import lazySizes from 'lazysizes'
import 'lazysizes/plugins/aspectratio/ls.aspectratio'
/* global Waypoint */
import 'waypoints/lib/jquery.waypoints.min.js'

// bootstrap => customisez bootstrap directement depuis un fichier custom et sélectionnez les modules communs utilisés sur toutes les pages du site au lieu de tout importer
import './scss/bootstrap'
// global styles
import './scss/custom'

// Scripts librairies
import { handle, sticky } from './js/libs/misc'
import './js/libs/utils'

// lazySizes.cfg.expand = 80
// lazySizes.cfg.expFactor = 1
$('body').removeClass('no-js')

// lazysizes : add simple support for background images :
document.addEventListener('lazybeforeunveil', (e) => {
  const bg = e.target.getAttribute('data-background')
  if (bg) e.target.style.backgroundImage = 'url(' + bg + ')'
})

// Real Estate Search Modal
async function getResModal ($f) {
  $f.load()
  import(/* webpackChunkName: "realEstateSearchModal" */ './js/components/realEstateSearchModal.js').then(() => {
    $f.load(false)
  })
}

// Real Estate Locations Autocomplete
async function getRELAutocomplete ($f) {
  import(/* webpackChunkName: "realEstateLocationsAutocomplete" */ './js/components/realEstateLocationsAutocomplete.js').then(module => {
    const form = module.relAutocomplete
    form.init($f)
  })
}

// Contact rapide
async function getQuickContact ($f) {
  $f.load()
  import(/* webpackChunkName: "quickContact" */ './js/components/quickContact.js').then(() => {
    $f.load(false)
  })
}

// Bouton de style pour le contact rapide
async function getQuickContactBtn () {
  import(/* webpackChunkName: "quickContactBtn", webpackPrefetch: true */ './scss/components/quickContactBtn.scss').then(() => {
    $('#quick-contact-btn').stop(true, true).animate({ opacity: 1 }, 300).removeClass('d-none')
  })
}

// Scroll to top
async function getScrollToTop () {
  import(/* webpackChunkName: "scrollToTop" */ './js/components/scrollToTop.js')
}

// Swiper Carousel
async function getSwiperCarousel () {
  import(/* webpackChunkName: "swiperCarousel" */ './js/components/swiperCarousel.js').then(() => {
    Waypoint.refreshAll()
  })
}

// Real Estate Alert Form
async function getREAForm ($f) {
  $f.load()
  import(/* webpackChunkName: "realEstateAlertsForm" */ './js/components/realEstateAlertsForm.js').then(() => {
    $f.load(false)
  })
}

// Actions principales communes à toutes les pages
$(document).ready(function () {
  // sur le scroll
  const w = $(window)
  w.scroll(function () {
    if (w.scrollTop() >= 150 && !$('#scrollUp').length) { getScrollToTop() }
    sticky()
  })

  // bouton recherche rapide
  $('.resModal').on('click', function () {
    $(this).off()
    getResModal($(this))
  })

  // filtres des offres autocomplete
  $('.resform form input[name="location"]').on('click keyup focus', function () {
    $(this).blur().off().attr('disabled', '').val('Veuillez patienter...')
    getRELAutocomplete($(this).parents('form'))
  })

  // bouton contact rapide
  $('#quick-contact-btn').on('click', function () {
    $(this).off()
    getQuickContact($(this))
  })

  // contact rapide
  if (!$('body').hasClass('error-404') && !$('body').hasClass('error-503')) {
    setTimeout(function () {
      getQuickContactBtn()
    }, 8000)
  }

  // gestion real estate offer items
  const $reoi = $('.reo-item')
  if ($reoi.length) {
    $reoi.waypoint(function () {
      import(/* webpackChunkName: "realEstateOfferItem" */ './scss/components/realEstateOfferItem.scss').then(() => {
        Waypoint.refreshAll()
      })
      this.destroy()
    }, {
      offset: '100%'
    })
  }

  // gestion des carousel
  const $s = $('.swiper-carousel')
  if ($s.length) {
    $s.waypoint(function () {
      getSwiperCarousel()
      this.destroy()
    }, {
      offset: '100%'
    })
  }

  // gestion du formulaire d'inscription aux alertes
  const $reaf = $('.reaform')
  if ($reaf.length) {
    $reaf.waypoint(function () {
      getREAForm($reaf)
      this.destroy()
    }, {
      offset: '100%'
    })
  }

  // boutons liens
  $('button[data-toggle="link"]').on('click', function () {
    if ($(this).attr('data-target') === 'self') { document.location.href = $(this).attr('data-href') } else { window.open($(this).attr('data-href')) }
  })

  // ancre
  $('.scroll').each(function () {
    const me = $(this)
    me.on('click', function (e) {
      const t = ($(e.target).is('a')) ? this.hash : $(this).attr('target')
      e.preventDefault()
      if ($(t).length > 0) {
        $('html').animate({ scrollTop: ($(t).offset().top - $('#header').height()) - 20 }, { duration: 200, easing: 'linear' }, function () {
          $('html').animate({ scrollTop: ($(t).offset().top - $('#header').height()) - 20 }, { duration: 200, easing: 'linear' })
        })
      }
    })
  })

  // refresh waypoints au chargement d'une image
  $('.lazyload').on('lazyloaded', () => {
    Waypoint.refreshAll()
  })

  // handle sur toutes les pages du site
  handle()
  sticky()
})
