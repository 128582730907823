// Charge la base commune
import './common.js'

// Real Estate Search Form
async function getRESForm ($f) {
  $f.load()
  import(/* webpackChunkName: "realEstateSearchForm", webpackPrefetch: true */ './js/components/realEstateSearchForm.js').then(() => {
    /* global Waypoint */
    Waypoint.refreshAll()
    $f.load(false)
  })
}

// Masonry
async function getMasonry () {
  import(/* webpackChunkName: "masonry" */ './js/components/masonry.js').then(() => {
    Waypoint.refreshAll()
  })
}

// actions principales communes à toutes les pages
$(document).ready(function () {
  // gestion du formulaire de recherche des biens immobiliers
  const $resf = $('.resform:not(.modal)')
  if ($resf.length) {
    $resf.waypoint(function () {
      getRESForm($resf)
      this.destroy()
    }, {
      offset: '100%'
    })
  }

  // gestion du masonry
  const $g = $('.grid')
  if ($g.length) {
    $g.waypoint(function () {
      getMasonry()
      this.destroy()
    }, {
      offset: '100%'
    })
  }
})
